<template>
  <div class="app-container">
    
    <!-- 顶部按钮 -->
    <div class="top_btns clearfix">
      <div class="left_search fl">
        <el-button class="filter-item" type="success" size="small" @click="goback()" plain   >
          <el-icon><Back /></el-icon><span  > 返回</span>
        </el-button>
        <el-input placeholder="请输入姓名查询" v-model="listQuery.name" style="width: 200px;" class="filter-item" size="small" />
        <el-button class="filter-item" @click="getList()" type="primary" size="small"   >
          <el-icon><Search /></el-icon><span  > 查询</span>
        </el-button>
      </div>
      <div class="right_btns fr">
        <qzf-button button_code="xc_gzb_tj" type="success" size="small" @success="addPersonal"   >
          <el-icon><CirclePlus /></el-icon><span  > 添加</span>
        </qzf-button>  
        <qzf-import-back @success="getList()" from="劳务报酬" @success2="getCallList" v-if="$buttonStatus('xc_gzb_dr')"></qzf-import-back>

        <el-dropdown style="margin-left: 12px;" size="small">
          <qzf-button jz="false" button_code="xc_gzb_tj" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a" @click="printingA4()">
                <span>打印A4</span>
              </el-dropdown-item>
              <el-dropdown-item command="b" @click="printingA5()">
                <span>打印凭证纸</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" @click="GetInportListClick()">
                <span>导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <!-- 顶部按钮 end -->
    <!-- 内容 -->
  <el-table stripe :data="listtable" v-loading="this.isLoading" :height="this.contentStyleObj" @selection-change="handleSelectionChange" style="width: 100%; margin-top: 10px;" show-summary>
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column align="center" type="selection" width="55"> </el-table-column>
    <el-table-column align="center" type="index" fixed width="50" label="序号"> </el-table-column>

    <el-table-column prop="name" label="姓名" fixed width="100" align="center"></el-table-column>

    <el-table-column align="center" label="证件号码" min-width="190">
      <template #default="{row}">
        <span>{{row.idNo}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="sdxm" label="所得项目" min-width="220">
      <template #default="{row}">
        <el-select v-model="row.sdxm" size="small">
          <el-option label="一般劳务报酬所得" value="一般劳务报酬所得"></el-option>
          <el-option label="法律援助补贴劳务报酬" value="法律援助补贴劳务报酬"></el-option>
          <el-option label="其他非连续劳务报酬" value="其他非连续劳务报酬"></el-option>
        </el-select>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="sr" label="收入" min-width="120">
      <template #default="{row}">
        <qzf-input v-model:num="row.sr" class="filter-item" size="small" style="min-width: 100px" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="mssr" label="免税收入" min-width="120">
      <template #default="{row}">
        <qzf-input v-model:num="row.mssr" class="filter-item" size="small" style="min-width: 100px" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="syjkbx" label="商业健康保险" min-width="120">
      <template #default="{row}">
        <qzf-input v-model:num="row.syjkbx" class="filter-item" size="small" style="min-width: 100px" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="syylbx" label="税延养老保险" min-width="120">
      <template #default="{row}">
        <qzf-input v-model:num="row.syylbx" class="filter-item" size="small" style="min-width: 100px" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="qt" label="其他" min-width="120">
      <template #default="{row}">
        <qzf-input v-model:num="row.qt" class="filter-item" size="small" style="min-width: 100px" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="yxkcsf" label="允许扣除的税费" min-width="120">
      <template #default="{row}">
        <qzf-input v-model:num="row.yxkcsf" class="filter-item" size="small" style="min-width: 100px" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="jmse" label="减免税额" min-width="120">
      <template #default="{row}">
        <qzf-input v-model:num="row.jmse" class="filter-item" size="small" style="min-width: 100px" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="tax" label="税额" min-width="120">
      <template #default="{row}">
        <span>{{row.tax}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="remark" label="备注" min-width="120">
      <template #default="{row}">
        <el-input placeholder="" v-model="row.remark" class="filter-item" size="small" style="width: 100px" />
      </template>
    </el-table-column>

    <el-table-column align="center" label="操作" min-width="120">
      <template #default="{row}">
        <el-tooltip content="保存" placement="top" effect="dark">
          <i class="iconfont icon-baocun" style="cursor: pointer;" @click="savadd(row)"></i>
        </el-tooltip>
        <el-tooltip content="删除" placement="top" effect="dark">
          <i class="iconfont icon-shanchu" @click="handleModifyStatus(row)"></i>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <div class="saveAll">
    <qzf-button button_code="xc_gzb_tj" v-loading.fullscreen.lock="fullscreenLoading" type="success" size="small" @success="saveAll()" >
      <el-icon size="14"><Finished /></el-icon><span  > 批量保存</span>
    </qzf-button>
    <qzf-button button_code="xc_gzb_tj" v-loading.fullscreen.lock="fullscreenLoading" type="danger" size="small" @success="delAll()" plain>
      <el-icon size="14"><Delete /></el-icon> <span  > 批量删除</span>
    </qzf-button>
  </div>
  <!-- 内容 end -->
  </div>
  <addNormal ref="addNormal" @success="getList" :type="'劳务报酬所得'"></addNormal>
  <print-setting ref="printSetting" @success="printSettingA5"></print-setting>
  <daoruHint ref="daoruHint" />
  <!-- <qzf-button button_code="xc_gzb_tj" v-loading.fullscreen.lock="fullscreenLoading" class="saveAll" type="success" size="small" @success="saveAll()" plain>批量保存</qzf-button> -->
</template>

<script>
import addNormal from './components/addNormal.vue'
import { salaryRemunerationList,saveEaSalaryRemuneration,delSalaryRem } from '@/api/salary.js'
import { printSalary } from "@/api/printSet"
import { exportSalary } from "@/api/export"
import daoruHint from './components/daoruHint.vue'


export default {
  components: {
    addNormal,
    daoruHint
  },
  data() {
    return {
      listtable: [],
      addall:[],
      dialogFormVisibleall: false,
      listQuery: {
        sheetId: this.$store.getters["commons/params"].salaryServicesId,
        name:'',
      },
      isLoading:false,
      fullscreenLoading:false,
      allids:[],
      sels:[],
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(190)
    this.getList()
  },
  methods: {
    goback() {
      this.$router.push({
        path: "/input/remuneration/remuneration",
        name: "remuneration"
      });
    },
    changeChecked(e){
      if(e){
        this.listtable.map(v=>{v.checked = true})
      }else{
        this.listtable.map(v=>{v.checked = false})
      }
      this.chulid()
    },
    handleSelectionChange(e) {
      this.allids = e.map(v=>{
        return {id:v.id}
      })
      this.sels=e
      console.log(this.sels);
    },
    // 保存
    savadd(row) {
      if(this.$checkSettleStatus()) return
      row.edit = false;
      saveEaSalaryRemuneration([row]).then(res => {
        if(res.data.msg == "success") {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.getList()
        }
      })
    },
    //删除
    handleModifyStatus(e) {
      if(this.$checkSettleStatus()) return
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSalaryRem([{id: e.id}]).then(res => {
          if(res.data.msg == "success") {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.getList()
          }
        })        
      })
    },
    //批量删除
    delAll() {
      if(this.allids.length == 0) {
        this.$qzfMessage("请至少选择一条数据",1)
        return
      }
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSalaryRem(this.allids).then(res => {
          if(res.data.msg == "success") {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.getList()
          }
        })  
      })

    },
    //批量保存
    saveAll() {
      this.fullscreenLoading = true
      saveEaSalaryRemuneration(this.listtable).then(res => {
        this.fullscreenLoading = false
        if(res.data.msg != "success"){
          this.$message.error(res.data.msg);
        }else{
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        // this.$router.push({
        //   path: "/input/remuneration/remuneration",
        //   name: "remuneration"
        // });
        this.getList()
        this.$bus.emit('salarySheetUpdate')
      });
    },
    getList() {
      this.isLoading = true
      salaryRemunerationList(this.listQuery).then((res) => {
        this.isLoading = false

        this.listtable = res.data.data.list?res.data.data.list:[]
      });
    },
    //新增
    addPersonal() {
      this.$refs.addNormal.openDialog()
    },
    printingA4(){
      let paramA4 = {
        paperType:'A4-H',
        bookNames : ['salray'],
        type:'salaryServices',
        comIds:[this.$store.getters['user/comInfo'].comId*1],
        period:this.$store.getters['user/comInfo'].period,
        query:this.listQuery
      }
      printSalary(paramA4).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    printingA5(){
      this.$refs.printSetting.dayinCustomStatus = true
    },
    printSettingA5(params){
      params.bookNames = ['salray']
      params.query = this.listQuery
      params.type = 'salaryServices'
      printSalary(params).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    GetInportListClick(){
      let arr=[];
      this.sels.forEach(element => {
        arr.push(element.employeeId)
      });
      let param = {
        period:this.$store.getters['user/comInfo'].period,
        comId:this.$store.getters['user/comInfo'].comId*1,
        type:'salaryServices',
        empId:arr
      }
      exportSalary(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    getCallList(e){
      this.$refs.daoruHint.init(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.saveAll {
  margin-top: 15px;
}
.zcgzxc { 
    margin-top: 10px;
}
.zcgzxc   th {
    color: rgb(51, 51, 51);
    font-size: 14px;
}
table{
  table-layout: fixed;
}
.content{
  .ss{
    position:sticky;
      left:0; /* 首行永远固定在左侧 */
      z-index:1;
  }
  // width: 2500px;
  // margin: 0 auto;
  border:1px solid #c2c2c2 !important;
  border-bottom: none;
  border-right: none;
  border-collapse:collapse;
  .fixed_td{
    position: relative;
    td:nth-child(3){
      position:sticky;
      left:0; /* 首行永远固定在左侧 */
      z-index:1;
      border-left:1px solid #c2c2c2;
      &:after {
        width: 1px;
        background: #c2c2c2;
        content: " ";
        clear: both;
        height: 100%;
        position: absolute;
        right: -1px;
        top: 0;
      }
      &:before {
        width: 1px;
        background: #c2c2c2;
        content: " ";
        clear: both;
        height: 100%;
        position: absolute;
        left: -1px;
        top: 0;
      }
    }
  }
  
  span{
    line-height: 34px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
    background: #fff;
    
  }
  td{
    // width: 200px;
    border-right:1px solid #c2c2c2 !important;
    border-bottom:1px solid #c2c2c2 !important;
    line-height: 25px;
    padding:0 2px;
    color: #666;
    font-size: 14px;
    background: #fff;
  }
  
  input{
    width: 90%;
    line-height: 34px;
    padding: 6px 0 6px 6px;
    font-size: 14px;
  }
}
  i{
    // font-size: 8px;
    line-height: 8px;
    text-align: center;
    // color: var(--themeColor,#17a2b8);
    // font-size: 18px;
    // padding-right: 5px;
  }
  .icon-baocun,.icon-shanchu{
    color: var(--themeColor,#17a2b8);
    font-size: 18px;
    padding-right: 5px;
  }
.top_bg{
  td{
    text-align: center;
  }
  .icon-tongbu1{
    display:inline-block;
    font-size: 16px;
    color: #67c23a;
    line-height: 26px;
    transform: translateY(2px);
  }
}
.center{
  text-align: center;
}
</style>
<style lang="scss" scoped>
 
.el-icon-document,.iconfont icon-bianji,.iconfont icon-shanchu {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor,#17a2b8);
  font-size: 18px;
  line-height: 24px;
}
</style>
<style>
/* .el-input--small .el-input__inner {
  height: 28px;
  line-height: 28px;
}
.el-input--medium .el-input__inner {
  height: 28px;
  line-height: 28px;
} */
.xiaxuan{
  cursor: pointer;
  color: black;
}
.filter-item {
  margin-right: 10px;
}

.table-head{ width: 1800px; background-color:#999;color:#000;}
/* .table-body{ width: 1800px; height:500px;overflow:scroll;} */
.table-body{ width: 1800px; height:500px;}
</style>